<template>
	<div class="gallery">

		<div class="gallery-item">
			<img class="gallery-image" src="./../assets/logo/save-the-date-aaron.jpg" alt="">
		</div>
		<div class="gallery-item">
			<img class="gallery-image" src="./../assets/logo/IMG_0194.jpg" alt="">
		</div>

		<div class="gallery-item">
			<img class="gallery-image" src="./../assets/logo/IMG_0290-1.jpg" alt="">
		</div>

		<div class="gallery-item">
			<img class="gallery-image" src="./../assets/logo/IMG_0293-1.jpg" alt="">
		</div>
		<div class="gallery-item">
			<img class="gallery-image" src="./../assets/logo/share-24844D64-B66F-42E3-84A9-8FF170BFA5EF.jpeg" alt="">
		</div>
		<div class="gallery-item">
			<img class="gallery-image" src="./../assets/logo/Invitación.jpg" alt="">
		</div>
		...
	</div>
</template>

<script>

export default {
  name: 'Gallery'
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/*
All grid code is placed in a 'supports' rule (feature query) at the bottom of the CSS (Line 77). 
The 'supports' rule will only run if your browser supports CSS grid.
Flexbox is used as a fallback so that browsers which don't support grid will still recieve an identical layout.
*/

.gallery {
	display: flex;
	flex-wrap: wrap;
	/* Compensate for excess margin on outer gallery flex items */
	margin: -1rem -1rem;
  padding-bottom: 5rem;

}

.gallery-item {
	/* Minimum width of 24rem and grow to fit available space */
	flex: 1 0 24rem;
	/* Margin value should be half of grid-gap value as margins on flex items don't collapse */
	margin: 1rem;
	box-shadow: 0.3rem 0.4rem 0.4rem rgba(0, 0, 0, 0.4);
	overflow: hidden;
}

.gallery-image {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
	transition: transform 400ms ease-out;
}

.gallery-image:hover {
	transform: scale(1.15);
}

/*
The following rule will only run if your browser supports CSS grid.
Remove or comment-out the code block below to see how the browser will fall-back to flexbox styling. 
*/

@supports (display: grid) {
	.gallery {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(24rem, 1fr));
		grid-gap: 2rem;
	margin-bottom: 3rem;
	}
	.gallery {
	margin-bottom: 5rem;
	}
}

</style>
