<template>
  <main class="grid">
    <article @click="whatsapp">
      <img
        src="./../assets/logo/share-FEBDE375-9DB7-4999-85E1-338ED6AA9A0C.jpeg"
        alt=""
      />
      <i class="fa fa-whatsapp"></i>
      <div class="text">
        <h3>¡Abre este enlace para unirte a mi grupo de WhatsApp!</h3>
      </div>
    </article>
    <article @click="telegram">
      <img src="./../assets/logo/IMG_0256-1.jpg" alt="" />
      <i class="fa fa-telegram"></i>
      <div class="text">
        <h3>¡Enlace de Telegram!</h3>
      </div>
    </article>
    <article @click="instagram">
      <img src="./../assets/socials/sterlinglanier-lanier-0FoWAzc0N6k-unsplash.jpg" alt="" />
      <i class="fa fa-instagram"></i>
      <div class="text">
        <h3>¡Visita nuestro Instagram!</h3>
      </div>
    </article>
    <article @click="buymeacoffee">
      <img src="./../assets/socials/annie-shelmerdine-oTH0rBJi86k-unsplash.jpg" alt="" />
      <i class="fa fa-coffee"></i>
      <div class="text">
        <h3>¡Cómpranos café!</h3>
      </div>
    </article>
    <article @click="paypal">
      <img src="./../assets/socials/abhishek-babaria-MfRNSuqxr3U-unsplash.jpg" alt="" />
      <i class="fa fa-paypal"></i>
      <div class="text">
        <h3>¡Puedes apoyarnos directamente en Paypal!</h3>
      </div>
    </article>
  </main>
</template>

<script>
export default {
  name: 'Socials',
  methods: {
    whatsapp() {
      window.open('https://chat.whatsapp.com/JvWhoqTZ1WR09IjLEtpVaH', '_blank');
    },
    telegram() {
      window.open('https://t.me/joinchat/NdHjuXX-2vYxMTM0', '_blank');
    },
    instagram() {
      window.open('https://instagram.com/aaronysofiinmision?utm_medium=copy_link', '_blank');
    },
    buymeacoffee() {
      window.open('https://www.buymeacoffee.com/AaronySofi', '_blank');
    },
    paypal() {
      window.open('https://paypal.me/ajLozaZ?locale.x=es_ES', '_blank');
    },
	
	
	
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.grid {
  font-family: Workman, 'lato', 'open sans', 'helvetica', sans-serif;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  grid-gap: 20px;
  align-items: stretch;
  font-size: 1rem;
  padding-bottom: 7rem;
}

.grid > article {
  border: 1px solid #ccc;
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.3);
  text-align: center;
}

.grid > article img {
  max-width: 100%;
}

.grid > article i {
  font-size: 48px;
  line-height: 70px;
  height: 4.5rem;
  width: 4.5rem;
  border: 6px solid white;
  border-radius: 50%;
  text-align: center;
  color: white;
  margin-top: -36px;
}
.fa .fa-whatsapp {
  background-color: #25d366;
}
.fa .fa-telegram {
  background-color: #0088cc;
}
.fa .fa-instagram {
  background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
}
.fa .fa-coffee {
  background-color: #6f4e37;
}
.fa .fa-paypal {
  background-color: #00457C;
}




.grid .text {
  padding: 16px 20px 30px;
}

.grid .btn {
  margin-top: 20px;
}
</style>
