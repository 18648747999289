<template>
  <div id="app">
    <Main msg="Bienvenido a la Boda de Aaron & Sofi"/>
  </div>
</template>

<script>
import Main from './components/Main.vue'

export default {
  setup() {
    return {}
  },
  name: 'App',
  components: {
    Main
  }
}
</script>

<style>
html, body {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  transition: all .6s cubic-bezier(.5, .2, .2, 1.1);
  -webkit-transition: all .6s cubic-bezier(.5, .2, .2, 1.1);
  -moz-transition: all .6s cubic-bezier(.5, .2, .2, 1.1);
  -o-transition: all .6s cubic-bezier(.5, .2, .2, 1.1);  
  color: #fff;
  overflow: hidden; 
  position: relative;
}
#app {
  font-family: 'lato', 'open sans', 'helvetica', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
@font-face {
  font-family: heritageItalics;
  src: url('./assets/fonts/HeritageItalics.otf');
}
@font-face {
  font-family: JoyfulBotans;
  src: url('./assets/fonts/JoyfulBotans.otf');
}
@font-face {
  font-family: scotland;
  src: url('./assets/fonts/scotland.otf');
}
@font-face {
  font-family: TheCraigievarFont;
  src: url('./assets/fonts/TheCraigievarFont.otf');
}
@font-face {
  font-family: Workman;
  src: url('./assets/fonts/Workman.otf');
}
@font-face {
  font-family: ErikaOrmig;
  src: url('./assets/fonts/ErikaOrmig.ttf');
}
</style>
