
import firebase from 'firebase/app'
require('firebase/auth')
require('firebase/database')
require('firebase/firestore')
require('firebase/functions')



// import { ref, onUnmounted, computed } from 'vue'

firebase.initializeApp({
  apiKey: "AIzaSyBSltKIDwVj-AKW2cW215zhkSCrHdHapmU",
  authDomain: "wedding-7dc7f.firebaseapp.com",
  databaseURL: "https://wedding-7dc7f-default-rtdb.firebaseio.com",
  projectId: "wedding-7dc7f",
  storageBucket: "wedding-7dc7f.appspot.com",
  messagingSenderId: "655123923004",
  appId: "1:655123923004:web:6fc3503304b995bd2dc339",
  measurementId: "G-WT2JZWS2FQ"
})

export function helloFirebase(payload) {
  console.log('helloFirebase payload', payload)
  // 🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥
  // The Firebase SDK is initialized and available here!

  var app = firebase.app()
  console.log('App', app)
  /* 
  firebase.auth().onAuthStateChanged(user => {
    console.log('User', user)
  });
  firebase.database().ref('/path/to/ref').on('value', snapshot => {
    console.log('Snapshot', snapshot)
  });
  firebase.firestore().doc('/foo/bar').get().then(() => { });
 */
  firebase.functions().httpsCallable('helloWorld')(payload).then((response) => { 
    console.log(response)
  });

  /* firebase.messaging().requestPermission().then(() => { });
  firebase.storage().ref('/path/to/ref').getDownloadURL().then(() => { });
  firebase.analytics(); // call to activate
  firebase.analytics().logEvent('tutorial_completed');
  firebase.performance(); // call to activate
 */
  // 🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥
}
/*
export function helloFirebase(payload) {
  // firebase.auth();
  console.log('Hello from js!')
  firebase.app();
  firebase.functions();
  let firebaseResponse;
  firebase.functions().httpsCallable('helloWorld')(payload).then((res) => {
    console.log('Hello World:', res)
    firebaseResponse = res
  });
  return { firebaseResponse }
} */
/*
const auth = firebase.auth()

export function useAuth() {
  const user = ref(null)
  const unsubscribe = auth.onAuthStateChanged(_user => (user.value = _user))
  onUnmounted(unsubscribe)
  const isLogin = computed(() => user.value !== null)

  const signIn = async () => {
    const googleProvider = new firebase.auth.GoogleAuthProvider()
    await auth.signInWithPopup(googleProvider)
  }
  const signOut = () => auth.signOut()

  return { user, isLogin, signIn, signOut }
}

const firestore = firebase.firestore()
const messagesCollection = firestore.collection('messages')
const messagesQuery = messagesCollection.orderBy('createdAt', 'desc').limit(100)

export function useChat() {
  const messages = ref([])
  const unsubscribe = messagesQuery.onSnapshot(snapshot => {
    messages.value = snapshot.docs
      .map(doc => ({ id: doc.id, ...doc.data() }))
      .reverse()
  })
  onUnmounted(unsubscribe)

  const { user, isLogin } = useAuth()
  const sendMessage = text => {
    if (!isLogin.value) return
    const { photoURL, uid, displayName } = user.value
    messagesCollection.add({
      userName: displayName,
      userId: uid,
      userPhotoURL: photoURL,
      text: text,
      createdAt: firebase.firestore.FieldValue.serverTimestamp()
    })
  }

  return { messages, sendMessage }
}
*/
