<template>
  <div class="ct" id="t1">
    <div class="ct" id="t2">
      <div class="ct" id="t3">
        <div class="ct" id="t4">
          <div class="ct" id="t5">
            <ul id="menu">
              <a href="#t1"><li class="icon fa fa-heart" id="uno"></li></a>
              <a href="#t2"><li class="icon fa fa-ellipsis-h" id="dos"></li></a>
              <a href="#t3"><li class="icon fa fa-camera" id="tres"></li></a>
              <a href="#t4"><li class="icon fa fa-video-camera" id="cuatro"></li></a>
              <a href="#t5"><li class="icon fa fa-plus-circle" id="cinco"></li></a>
            </ul>
            <div class="page" id="p1">
              <section>
                <img class="cover-height" src="./../assets/backgrounds/02.jpg" alt="" />

                <iframe
                  width="100%"
                  height="720"
                  src="https://www.youtube.com/embed/ToToXBADdg4"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </section>
            </div>
            <div class="page" id="p2">
              <section class="icon fa fa-fa-heart">
                <span class="title"> {{ msg }} </span>
                <span class="hint">
                  ¡Hola familia! Bienvenidos a nuestra Boda Online, estamos muy felices y
                  agradecidos de poder compartir este momento tan especial contigo y que
                  puedas ser testigo de nuestro amor.
                </span>
                <hr />
                <span class="title">Nosotros...</span>
                <span class="hint">
                  Somos unos apasionados de vivir por Dios y deseamos entregarle toda
                  nuestra vida a Él.
                </span>
                <span class="hint">
                  Nos conocimos queriendo servirle, así creamos una preciosa amistad donde
                  vimos que nuestros propósitos se unían y decidimos iniciar nuestro
                  noviazgo. Tras un año y medio juntos lleno de crecimiento y aprendizaje
                  en amor, dimos el gran paso de comprometernos.💍
                </span>
                <span class="hint">
                  Desde que comenzamos a planificar la boda hemos visto el respaldo de
                  Dios en cada reto, por grande o pequeño que fuese y por fin podemos
                  hacerte parte de este gran día, queremos que seas testigo del pacto de
                  Amor que hacemos ante Dios de casarnos en matrimonio y que puedas
                  celebrar con nosotros la fidelidad de Dios y compartir su amor. Soñamos
                  con unir nuestras vidas y entregarnos a Dios hasta el fin, amándonos y
                  respetándonos, siéndonos fiel en lo bueno y en lo malo, en la riqueza y
                  en la pobreza, con pandemia o sin pandemia 😅 todos los días de nuestra
                  vida.
                </span>
                <iframe
                  width="100%"
                  height="420"
                  src="https://www.youtube.com/embed/6naC0Bz1k7A"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
                <Socials />

              </section>

            </div>
            <div class="page" id="p3">
              <section class="icon fa fa-camera">
                <span class="title">Fotos</span>
                <Gallery />
              </section>
            </div>
            <div class="page" id="p4">
              <section class="icon fa fa-video-camera">
                <span class="title">Live!</span>
                <a  v-if="false" 
                  href="#!"
                  style="font-size: 1.5rem; color: white; text-decoration: underline"
                  >Iniciar Sesión</a
                >
                <p class="hint">
                  <iframe
                    width="100%"
                    height="315"
                    src="https://www.youtube.com/embed/8zc9xReBCLw"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </p>
              </section>
            </div>
            <div class="page" id="p5">
              <section class="icon fa fa-plus-circle">
                <span class="title">Nuestra Boda</span>
                <span class="hint">Más información sobre la boda</span>
                <div v-if="false"
                  @click="firebaseCall"
                  style="font-size: 1.5rem; color: grey; text-decoration: underline"
                >
                  Iniciar Sesión
                </div>

                <div>
                  <span class="title">Dresscode</span>
                  <span class="hint">
                    Hola soy Sofi!
                    <br />
                    <br />
                    Sabemos que asistiréis todos guapísimos y sobre todo queremos que
                    disfrutéis con nosotros :) La boda será rural y por eso queremos daros
                    algunos consejos para el gran día. ¿Qué me pongo?
                  </span>
                  <div class="two-columns">
                    <img
                      class="image"
                      src="./../assets/dresscode/image_6483441(6).jpg"
                      alt=""
                    />
                    <img
                      class="image"
                      src="./../assets/dresscode/image_6483441.jpg"
                      alt=""
                    />
                  </div>
                  <img
                    class="image lone"
                    src="./../assets/dresscode/image_6487327.jpg"
                    alt=""
                  />
                  <span class="hint">
                    CHICAS: Como todas somos tan detallistas cuando nos arreglamos
                    queremos combinar con todo y vernos bellísimas, os dejamos por aquí
                    los tonos de la boda para que lo uséis como guía al elegir vuestros
                    vestidos. Por cómo es el lugar de la celebración, mi recomendación es
                    NO tacones altos, mejor cuñas o sandalias, recordad que sentirse
                    cómodas es lo primero! Y para elegir correctamente que ponerte ese día
                    mi consejo es que sea informal y casual , la boda será íntima y
                    sencilla, la temática por si te ayuda será Bohemia y Farmhouse. Así
                    que puedes elegir un conjunto de falda y camisa, un mono o un vestido
                    ¡con lo que sea te verás increíble!
                  </span>
                  <div class="two-columns">
                    <img
                      class="image"
                      src="./../assets/dresscode/image_6483441(1).jpg"
                      alt=""
                    />
                    <img
                      class="image"
                      src="./../assets/dresscode/image_6483441(2).jpg"
                      alt=""
                    />
                  </div>
                  <hr />
                  <img
                    class="image lone"
                    src="./../assets/dresscode/image_6483441(4).jpg"
                    alt=""
                  />
                  <span class="hint">
                    CHICOS : Recordemos que la Boda será un 19 de Junio así que hará calor
                    y hemos escogido que sea informal y sencilla, mi recomendación es
                    dejar la chaqueta en casa y venir solo con camisa o blusa. También
                    podéis fijaros en los tonos que hemos elegido para que escojáis el
                    color correcto, a parte os dejamos algunas fotos de inspiración por
                    aquí. Y sobre el calzado... mejor que no uséis tacones :P Tonos de la
                    Boda: Cualquier duda o pregunta, contáctanos y te respondemos cuanto
                    antes ¡Qué ganas de verte!
                  </span>
                  <div class="two-columns">
                    <img
                      class="image"
                      src="./../assets/dresscode/image_6483441(3).jpg"
                      alt=""
                    />
                    <img
                      class="image"
                      src="./../assets/dresscode/image_6483441(5).jpg"
                      alt=""
                    />
                  </div>
                  <span class="hint">
                    Regalos: Tema incomodo, REGALOS. Para nosotros el mejor regalo es que
                    seáis partícipes de nuestra boda. Aunque sabemos que lo normal es dar
                    un pequeño regalo, por el tipo de vida que hemos elegido Sofía y yo,
                    tenemos claro que no queremos cosas materiales. Si es tu deseo
                    ayudarnos con un regalo en nuestra nueva etapa, preferimos que sea
                    económico sin importar que sea mucho o poco. Para ello , aquí mismo te
                    dejamos todas las facilidades para hacerlo y os dejamos un vídeo
                    explicando el tipo de vida que queremos tener y cómo puedes ayudarnos
                  </span>
                  <span class="hint">
                    Ubicación:
                    <a
                      href="https://maps.google.com/?q=36.603622,-4.621298"
                      target="_blank"
                      >Dirección</a
                    ></span
                  >
                  <span class="hint">
                    - Menu Sobre el menú, tenemos pensado algo muy callejero, sabemos que
                    no es lo común, pero es una buena oportunidad de combinar estilos que
                    nos gustan mucho, mediterráneo, turco, mexicano... Seguro que te
                    gustará. Si eres intolerante a alguna comida, por favor ponte en
                    contacto con nosotros.
                  </span>
                  
                  <div class="two-columns">
                    <img
                      class="image"
                      src="./../assets/menu/engin-akyurt-tu0mZzRcRLU-unsplash.jpg"
                      alt=""
                    />
                    <img
                      class="image"
                      src="./../assets/menu/jez-timms-BHD2OxkYGSk-unsplash.jpg"
                      alt=""
                    />
                  </div>
                  <div class="two-columns last">
                    <img
                      class="image"
                      src="./../assets/menu/natasha-bhogal-7sStoaxfJh0-unsplash.jpg"
                      alt=""
                    />
                    <img
                      class="image"
                      src="./../assets/menu/viktor-ritsvall-lKaHOuEce1w-unsplash.jpg"
                      alt=""
                    />
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '.././assets/css/font-awesome.min.css';
import Gallery from './Gallery.vue';
import Socials from './Socials.vue';
import { helloFirebase } from '@/firebase'

export default {
  name: 'Main',
  components: {
    Gallery,
    Socials,
  },
  props: {
    msg: String,
  },
  created() {
    this.firebaseCall();
  },
  methods: {
    firebaseCall() {
      helloFirebase({"token":"mytoken"})
      console.log('FirebaseCalls, in Vue!');
      /*
      firebase.functions().httpsCallable('helloWorld')().then(() => { });
      console.log(firebase);
      try {
        let app = firebase.app();
        let features = ['auth', 'database', 'firestore', 'functions'].filter(
          (feature) => typeof app[feature] === 'function'
        );
        console.log(features);
      } catch (e) {
        console.error(e);
      }

      firebase
        .functions()
        .httpsCallable('helloWorld')()
        .then((response) => {
          console.log(response);
        });
         */
    },
  },
};
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Eczar&family=Playfair+Display&display=swap');
</style> 
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.page {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  transition: all 0.6s cubic-bezier(0.5, 0.2, 0.2, 1.1);
  -webkit-transition: all 0.6s cubic-bezier(0.5, 0.2, 0.2, 1.1);
  -moz-transition: all 0.6s cubic-bezier(0.5, 0.2, 0.2, 1.1);
  -o-transition: all 0.6s cubic-bezier(0.5, 0.2, 0.2, 1.1);
  color: #fff;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
}

#p1 {
  left: 0;
}

#p2,
#p3,
#p4,
#p5 {
  left: 200%;
}

#p1 {
  background: rgba(0, 0, 0, 0) url('./../assets/backgrounds/03.jpg') repeat scroll 0% 0%;
}
#p2 {
  background: rgba(0, 0, 0, 0) url('./../assets/backgrounds/03.jpg') repeat scroll 0% 0%;
}
#p3 {
  background: rgba(0, 0, 0, 0) url('./../assets/backgrounds/03.jpg') repeat scroll 0% 0%;
}
#p4 {
  background: rgba(0, 0, 0, 0) url('../assets/logo/save.jpg') repeat scroll 0% 0%;
  background-size: cover;
}
#p5 {
  background: rgba(0, 0, 0, 0) url('../assets/backgrounds/03.jpg') repeat scroll 0% 0%;
}

#t2:target #p2,
#t3:target #p3,
#t4:target #p4,
#t5:target #p5 {
  transform: translateX(-190%);
  -webkit-transform: translateX(-190%);
  -moz-transform: translateX(-190%);
  -o-transform: translateX(-190%);
  transition-delay: 0.4s !important;
}

/* #t2:target #p1,
#t3:target #p1,
#t4:target #p1,
#t5:target #p1 {
  background: black;
} */

#t2:target #p1 .icon,
#t3:target #p1 .icon,
#t4:target #p1 .icon,
#t5:target #p1 .icon {
  -webkit-filter: blur(3px);
}

.icon {
  font-size: 2rem;
  display: block;
}
#uno {
  color: #b08c44 !important;
}
#dos {
  color: #b08c44 !important;
}
#tres {
  color: #caab71 !important;
}
#cuatro {
  color: #dbc7a2 !important;
}
#cinco {
  color: #dbc7a2 !important;
}

section {
  color: #fff !important;
}

#p2 section {
  color: #555 !important;
}
#p3 section {
  color: #caab71 !important;
}
#p4 section {
  color: #eee !important;
}
#p5 section {
  color: #caab71 !important;
}
ul .icon:hover {
  opacity: 0.5;
}

.page .icon .title {
  line-height: 1.5;
}

#t2:target ul .icon,
#t3:target ul .icon,
#t4:target ul .icon,
#t5:target ul .icon {
  transform: scale(0.6);
  -webkit-transform: scale(0.6);
  -moz-transform: scale(0.6);
  -o-transform: scale(0.6);
  transition-delay: 0.25s;
}

#t2:target #dos,
#t3:target #tres,
#t4:target #cuatro,
#t5:target #cinco {
  color: #b08c44;
  transform: scale(1.2) !important;
  -webkit-transform: scale(1.2) !important;
  -moz-transform: scale(1.2) !important;
  -o-transform: scale(1.2) !important;
}

ul {
  position: fixed;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 10vw;
  padding: 0;
  text-align: center;
}

#menu .icon {
  margin: 1rem 0;
  transition: all 0.5s ease-out !important;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
}

a {
  text-decoration: none;
}

/* Page Styles */
.cover {
  max-width: 100%;
}
.cover-height {
  max-height: 100vh;
}
.title,
.hint {
  font-family: 'Eczar', 'lato', 'open sans', 'helvetica', sans-serif;
  display: block;
}

.title {
  font-family: Workman, 'lato', 'open sans', 'helvetica', sans-serif;
  font-size: 3rem;
}
.title,
.left {
  font-family: Workman, 'lato', 'open sans', 'helvetica', sans-serif;
  font-size: 3rem;
}
.title {
  font-family: Workman, 'lato', 'open sans', 'helvetica', sans-serif;
  font-size: 3rem;
  line-height: 1 !important;
  padding: 1rem;
}

.hint {
  font-size: 1.4rem;
  background: white;
  color: grey;
  padding: 1rem;
  margin-bottom: 1rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.6);
  transition: 0.3s;
}
.hint:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.4);
}
#p4 .hint {
  display: inherit !important;
}

.hint a {
  color: tomato;
  transition: all 250ms ease-out;
  -webkit-transition: all 250ms ease-out;
  -moz-transition: all 250ms ease-out;
  -o-transition: all 250ms ease-out;
}

.hint a:hover {
  color: red;
}

.line-trough {
  text-decoration: line-through;
}

.page .icon {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 10%;
  left: 0;
  width: 66vw;
  margin: auto;
  text-align: center;
  font-size: 80px;
  line-height: 1.3;
  transform: translateX(360%);
  -webkit-transform: translateX(360%);
  -moz-transform: translateX(360%);
  -o-transform: translateX(360%);
  transition: all 0.5s cubic-bezier(0.25, 1, 0.5, 1.25);
  -webkit-transition: all 0.5s cubic-bezier(0.25, 1, 0.5, 1.25);
  -moz-transition: all 0.5s cubic-bezier(0.25, 1, 0.5, 1.25);
  -o-transition: all 0.5s cubic-bezier(0.25, 1, 0.5, 1.25);
}
.page .icon p {
  font-family: 'lato', 'open sans', 'helvetica', sans-serif;
  font-size: 1.5rem;
}
.page#p1 .icon {
  height: 42rem;
}

.page#p1 .icon {
  transform: translateX(10%) !important;
}

.page#p3 .icon {
  width: 75vw;
}
#t2:target .page#p2 .icon,
#t3:target .page#p3 .icon,
#t4:target .page#p4 .icon,
#t5:target .page#p5 .icon {
  transform: translateX(0) !important;
  -webkit-transform: translateX(0) !important;
  -moz-transform: translateX(0) !important;
  -o-transform: translateX(0) !important;
  transition-delay: 1s;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 400ms ease-out;
}

.image:hover {
  transform: scale(1.05);
}
.lone {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.last {
  padding-bottom: 5rem;
}
.two-columns {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.three-columns {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
hr {
  margin-block-end: 0rem;
}
</style>
